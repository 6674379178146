import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _8a28287e = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _272244f8 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _63bb6ff7 = () => interopDefault(import('../pages/account/details/index.vue' /* webpackChunkName: "pages/account/details/index" */))
const _15cccd00 = () => interopDefault(import('../pages/account/funds.vue' /* webpackChunkName: "pages/account/funds" */))
const _023cf2ae = () => interopDefault(import('../pages/account/loyalities.vue' /* webpackChunkName: "pages/account/loyalities" */))
const _62165012 = () => interopDefault(import('../pages/account/newsletter.vue' /* webpackChunkName: "pages/account/newsletter" */))
const _e2331b92 = () => interopDefault(import('../pages/account/overview.vue' /* webpackChunkName: "pages/account/overview" */))
const _3a665978 = () => interopDefault(import('../pages/account/purchases.vue' /* webpackChunkName: "pages/account/purchases" */))
const _779405da = () => interopDefault(import('../pages/account/transactions.vue' /* webpackChunkName: "pages/account/transactions" */))
const _51c796fa = () => interopDefault(import('../pages/account/wishlist.vue' /* webpackChunkName: "pages/account/wishlist" */))
const _09275fdf = () => interopDefault(import('../pages/account/details/delivery.vue' /* webpackChunkName: "pages/account/details/delivery" */))
const _1e1eb592 = () => interopDefault(import('../pages/account/details/invoice.vue' /* webpackChunkName: "pages/account/details/invoice" */))
const _2d63130e = () => interopDefault(import('../pages/account/details/login.vue' /* webpackChunkName: "pages/account/details/login" */))
const _5c175e0e = () => interopDefault(import('../pages/account/details/profile.vue' /* webpackChunkName: "pages/account/details/profile" */))
const _0e92bef4 = () => interopDefault(import('../pages/account/details/store.vue' /* webpackChunkName: "pages/account/details/store" */))
const _795e32a6 = () => interopDefault(import('../pages/product/lister/actie.vue' /* webpackChunkName: "pages/product/lister/actie" */))
const _bcfd84bc = () => interopDefault(import('../pages/blog/category/index.vue' /* webpackChunkName: "pages/blog/category/index" */))
const _71e4ab3f = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _8959e23c = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _0f5d63e5 = () => interopDefault(import('../pages/checkout/index.vue' /* webpackChunkName: "pages/checkout/index" */))
const _1cc8bd31 = () => interopDefault(import('../pages/checkout/delivery.vue' /* webpackChunkName: "pages/checkout/delivery" */))
const _fe5eaa02 = () => interopDefault(import('../pages/checkout/information.vue' /* webpackChunkName: "pages/checkout/information" */))
const _cb637794 = () => interopDefault(import('../pages/checkout/overview.vue' /* webpackChunkName: "pages/checkout/overview" */))
const _40a44059 = () => interopDefault(import('../pages/checkout/payment.vue' /* webpackChunkName: "pages/checkout/payment" */))
const _4fca5817 = () => interopDefault(import('../pages/checkout-redirect.vue' /* webpackChunkName: "pages/checkout-redirect" */))
const _4178bd18 = () => interopDefault(import('../pages/checkout-success.vue' /* webpackChunkName: "pages/checkout-success" */))
const _1cf1ce95 = () => interopDefault(import('../pages/icon.vue' /* webpackChunkName: "pages/icon" */))
const _46025280 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _8305b544 = () => interopDefault(import('../pages/brand/index.vue' /* webpackChunkName: "pages/brand/index" */))
const _2d306798 = () => interopDefault(import('../pages/store/lister/index.vue' /* webpackChunkName: "pages/store/lister/index" */))
const _6d7e5b5c = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _89523e40 = () => interopDefault(import('../pages/blocks/demo.vue' /* webpackChunkName: "pages/blocks/demo" */))
const _1640c959 = () => interopDefault(import('../pages/forgot-password/reset.vue' /* webpackChunkName: "pages/forgot-password/reset" */))
const _772f2d2d = () => interopDefault(import('../pages/faq/index.vue' /* webpackChunkName: "pages/faq/index" */))
const _4fa02e74 = () => interopDefault(import('../pages/login/guest.vue' /* webpackChunkName: "pages/login/guest" */))
const _6227b8de = () => interopDefault(import('../pages/notification/stock/index.vue' /* webpackChunkName: "pages/notification/stock/index" */))
const _0b2e2642 = () => interopDefault(import('../pages/forgot-password/redirect.vue' /* webpackChunkName: "pages/forgot-password/redirect" */))
const _556340d8 = () => interopDefault(import('../pages/notification/redirect.vue' /* webpackChunkName: "pages/notification/redirect" */))
const _c06f4ba6 = () => interopDefault(import('../pages/blog/detail/_slug.vue' /* webpackChunkName: "pages/blog/detail/_slug" */))
const _37f68637 = () => interopDefault(import('../pages/review/detail/_slug.vue' /* webpackChunkName: "pages/review/detail/_slug" */))
const _73296cbc = () => interopDefault(import('../pages/review/writing/_slug.vue' /* webpackChunkName: "pages/review/writing/_slug" */))
const _d0428bf0 = () => interopDefault(import('../pages/product/detail/_slug.vue' /* webpackChunkName: "pages/product/detail/_slug" */))
const _f1e83d54 = () => interopDefault(import('../pages/store/detail/_slug.vue' /* webpackChunkName: "pages/store/detail/_slug" */))
const _5a978d02 = () => interopDefault(import('../pages/search/_slug.vue' /* webpackChunkName: "pages/search/_slug" */))
const _1ff83aa2 = () => interopDefault(import('../pages/blog/category/_.vue' /* webpackChunkName: "pages/blog/category/_" */))
const _fab750ce = () => interopDefault(import('../pages/blog/tag/_.vue' /* webpackChunkName: "pages/blog/tag/_" */))
const _714922bf = () => interopDefault(import('../pages/brand/lister/_.vue' /* webpackChunkName: "pages/brand/lister/_" */))
const _effd0d52 = () => interopDefault(import('../pages/product/lister/_.vue' /* webpackChunkName: "pages/product/lister/_" */))
const _b2b24234 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _3a94c5f3 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _8a28287e,
    children: [{
      path: "",
      component: _272244f8,
      name: "account___nl"
    }, {
      path: "details",
      component: _63bb6ff7,
      name: "account-details___nl"
    }, {
      path: "funds",
      component: _15cccd00,
      name: "account-funds___nl"
    }, {
      path: "loyalities",
      component: _023cf2ae,
      name: "account-loyalities___nl"
    }, {
      path: "newsletter",
      component: _62165012,
      name: "account-newsletter___nl"
    }, {
      path: "overview",
      component: _e2331b92,
      name: "account-overview___nl"
    }, {
      path: "purchases",
      component: _3a665978,
      name: "account-purchases___nl"
    }, {
      path: "transactions",
      component: _779405da,
      name: "account-transactions___nl"
    }, {
      path: "wishlist",
      component: _51c796fa,
      name: "account-wishlist___nl"
    }, {
      path: "details/delivery",
      component: _09275fdf,
      name: "account-details-delivery___nl"
    }, {
      path: "details/invoice",
      component: _1e1eb592,
      name: "account-details-invoice___nl"
    }, {
      path: "details/login",
      component: _2d63130e,
      name: "account-details-login___nl"
    }, {
      path: "details/profile",
      component: _5c175e0e,
      name: "account-details-profile___nl"
    }, {
      path: "details/store",
      component: _0e92bef4,
      name: "account-details-store___nl"
    }]
  }, {
    path: "/actie",
    component: _795e32a6,
    name: "product-lister-actie___nl"
  }, {
    path: "/blog",
    component: _bcfd84bc,
    name: "blog-category___nl"
  }, {
    path: "/cart",
    component: _71e4ab3f,
    name: "cart___nl"
  }, {
    path: "/checkout",
    component: _8959e23c,
    children: [{
      path: "",
      component: _0f5d63e5,
      name: "checkout___nl"
    }, {
      path: "delivery",
      component: _1cc8bd31,
      name: "checkout-delivery___nl"
    }, {
      path: "information",
      component: _fe5eaa02,
      name: "checkout-information___nl"
    }, {
      path: "overview",
      component: _cb637794,
      name: "checkout-overview___nl"
    }, {
      path: "payment",
      component: _40a44059,
      name: "checkout-payment___nl"
    }]
  }, {
    path: "/checkout-redirect",
    component: _4fca5817,
    name: "checkout-redirect___nl"
  }, {
    path: "/checkout-success",
    component: _4178bd18,
    name: "checkout-success___nl"
  }, {
    path: "/icon",
    component: _1cf1ce95,
    name: "icon___nl"
  }, {
    path: "/login",
    component: _46025280,
    name: "login___nl"
  }, {
    path: "/merk",
    component: _8305b544,
    name: "brand___nl"
  }, {
    path: "/vestigingen",
    component: _2d306798,
    name: "store-lister___nl"
  }, {
    path: "/wachtwoord-vergeten",
    component: _6d7e5b5c,
    name: "forgot-password___nl"
  }, {
    path: "/blocks/demo",
    component: _89523e40,
    name: "blocks-demo___nl"
  }, {
    path: "/forgot-password/reset",
    component: _1640c959,
    name: "forgot-password-reset___nl"
  }, {
    path: "/klantenservice/faq",
    component: _772f2d2d,
    name: "faq___nl"
  }, {
    path: "/login/guest",
    component: _4fa02e74,
    name: "login-guest___nl"
  }, {
    path: "/notification/stock",
    component: _6227b8de,
    name: "notification-stock___nl"
  }, {
    path: "/myaccount/resetpassword/redirect",
    component: _0b2e2642,
    name: "forgot-password-redirect___nl"
  }, {
    path: "/notification/confirm/stock",
    component: _556340d8,
    name: "notification-redirect___nl"
  }, {
    path: "/blog/detail/:slug?",
    component: _c06f4ba6,
    name: "blog-detail-slug___nl"
  }, {
    path: "/review/detail/:slug?",
    component: _37f68637,
    name: "review-detail-slug___nl"
  }, {
    path: "/review/writing/:slug?",
    component: _73296cbc,
    name: "review-writing-slug___nl"
  }, {
    path: "/p/:slug?",
    component: _d0428bf0,
    name: "product-detail-slug___nl"
  }, {
    path: "/winkel/:slug?",
    component: _f1e83d54,
    name: "store-detail-slug___nl"
  }, {
    path: "/zoek/:slug?",
    component: _5a978d02,
    name: "search-slug___nl"
  }, {
    path: "/blog/*",
    component: _1ff83aa2,
    name: "blog-category-all___nl"
  }, {
    path: "/tag/*",
    component: _fab750ce,
    name: "blog-tag-all___nl"
  }, {
    path: "/merk/*",
    component: _714922bf,
    name: "brand-lister-all___nl"
  }, {
    path: "/producten/*",
    component: _effd0d52,
    name: "product-lister-all___nl"
  }, {
    path: "/",
    component: _b2b24234,
    name: "index___nl"
  }, {
    path: "/*",
    component: _3a94c5f3,
    name: "all___nl"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
